// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.observationPhoto:hover {
    transform: scale(1.2);
    transition: transform 0.5s cubic-bezier(0.25, 0.45, 0.45, 0.95);
}

.selected {
    border-width: 2px;
    border-style: solid;
    border-color: blue;
}`, "",{"version":3,"sources":["webpack://./components/ObservationPhoto/ObservationPhoto.style.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,+DAA+D;AACnE;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":[".observationPhoto:hover {\n    transform: scale(1.2);\n    transition: transform 0.5s cubic-bezier(0.25, 0.45, 0.45, 0.95);\n}\n\n.selected {\n    border-width: 2px;\n    border-style: solid;\n    border-color: blue;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
