(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@reduxjs/toolkit"));
	else if(typeof define === 'function' && define.amd)
		define(["@Reduxjs/Toolkit"], factory);
	else if(typeof exports === 'object')
		exports["lib-am-common"] = factory(require("@reduxjs/toolkit"));
	else
		root["lib-am-common"] = factory(root["@Reduxjs/Toolkit"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__reduxjs_toolkit__) => {
return 