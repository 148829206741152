import { paginationDefault } from '@Reducers/paginationUtils';
import { Pagination } from '.';

export interface RiskConfigGrades {
	low: number;
	medium: number;
	high: number;
	extreme: number;
	max: number;
}

export interface RiskConfigList {
	configId: string;
	name: string;
}

export interface RiskConfigsListState {
	configs: RiskConfigList[];
	isLoading: boolean;
}

export enum ConfigPublishState {
	published = 'published',
	unpublished = 'unpublished',
}

export interface RiskPostProcessingNormalize {
	useNormalize?: boolean;
	normalizeValue?: number;
	maxRiskScore?: number;
}

// When you add a new type of post processing add the type string to type and create a new class to add to params
// e.g.
// export interface RiskPostProcessing {
// 	type: 'normalize' | 'denormalize';
// 	params: RiskPostProcessingNormalize | RiskPostProcessingDeormalize;
// }
// This way we keep the params grouped rather than have just a single object that has all possible optional values

export interface RiskPostProcessing {
	type: 'normalize';
	params: RiskPostProcessingNormalize;
}

export interface RiskConfigCard {
	configId: string;
	tenantId: string;
	name: string;
	publishState: ConfigPublishState;
	assetFilterId?: string;
	systemType: string;
	assetType: string;
	inspectionStandard?: string;
	grades: RiskConfigGrades;
	lastRun: string;
	lastRunCompleted?: string;
	createdAt: string;
	runBy?: string;
	status?: string;
	assetCount?: number;
	cofWeighting?: number;
	lofWeighting?: number;
	riskMethod?: string;
	postProcessing?: RiskPostProcessing[];
}

export interface RiskConfigCardPayload {
	pagination: Pagination;
	data: RiskConfigCard[];
}

export const riskConfigsPaginationDefault = (): Pagination => ({
	...paginationDefault,
	search: undefined,
	sortKey: 'createdAt',
	sortDescending: true,
});
