// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rec-arrow,
.rec-arrow:hover:enabled,
.rec-arrow:focus:enabled {
	background-color: white !important;
	box-shadow: 0 0 0 0 !important;
}

.rec-pagination {
	display: none !important;
}

.rec-arrow:hover:enabled,
.rec-arrow:focus:enabled {
	color: #333 !important;
}
`, "",{"version":3,"sources":["webpack://./components/AssetDetails/InspDetails/InspDetails.component.style.css"],"names":[],"mappings":"AAAA;;;CAGC,kCAAkC;CAClC,8BAA8B;AAC/B;;AAEA;CACC,wBAAwB;AACzB;;AAEA;;CAEC,sBAAsB;AACvB","sourcesContent":[".rec-arrow,\n.rec-arrow:hover:enabled,\n.rec-arrow:focus:enabled {\n\tbackground-color: white !important;\n\tbox-shadow: 0 0 0 0 !important;\n}\n\n.rec-pagination {\n\tdisplay: none !important;\n}\n\n.rec-arrow:hover:enabled,\n.rec-arrow:focus:enabled {\n\tcolor: #333 !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
