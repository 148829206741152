// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.normalCard {
    border-left: 6px solid #0071a4;
    padding: 5px 10px;
    width: 100%;
}

.errorCard {
    border-left: 6px solid red;
    padding: 5px 10px;
    width: 100%;
}

.warningCard {
    border-left: 6px solid yellow;
    padding: 5px 10px;
    width: 100%;
}

.informationCard {
    border-left: 6px solid green;
    padding: 5px 10px;
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./components/DetailsCard/DetailsCard.styles.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,0BAA0B;IAC1B,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,6BAA6B;IAC7B,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,4BAA4B;IAC5B,iBAAiB;IACjB,WAAW;AACf","sourcesContent":[".normalCard {\n    border-left: 6px solid #0071a4;\n    padding: 5px 10px;\n    width: 100%;\n}\n\n.errorCard {\n    border-left: 6px solid red;\n    padding: 5px 10px;\n    width: 100%;\n}\n\n.warningCard {\n    border-left: 6px solid yellow;\n    padding: 5px 10px;\n    width: 100%;\n}\n\n.informationCard {\n    border-left: 6px solid green;\n    padding: 5px 10px;\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
